<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link> / Agent /
          {{ account.name }}
        </h1>
      </v-flex>
      <v-flex xs12 md12 lg7 class="pl-2 pr-2">
        <v-simple-table>
          <tbody>
            <tr>
              <th class="fs-12 primary--text">Type</th>
              <th class="fs-12">{{ account.type }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Ref ID</th>
              <th class="fs-12 primary--text">
                <b>{{ account.ref_id }}</b>
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Name</th>
              <th class="fs-12">{{ account.name }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Email</th>
              <th class="fs-12">{{ account.email }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Phone</th>
              <th class="fs-12">
                {{ account.country_code }} {{ account.phone }}
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Phone Alt.</th>
              <th class="fs-12">
                {{ account.country_code }} {{ account.phone_alt }}
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Fax</th>
              <th class="fs-12">{{ account.fax }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Address</th>
              <th class="fs-12">{{ account.address }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">City</th>
              <th class="fs-12">{{ account.city }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Country</th>
              <th class="fs-12">{{ account.country }}</th>
            </tr>
            <tr v-if="country == 'United Arab Emirates'">
              <th class="fs-12 primary--text">Emirate</th>
              <th class="fs-12">{{ account.emirate }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">VAT</th>
              <th class="fs-12">{{ account.vat }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">TRN</th>
              <th class="fs-12">{{ account.trn }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Payment Mode</th>
              <th class="fs-12 green--text uppercase">
                {{ account.payment_mode }}
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Commission</th>
              <th class="fs-12 uppercase">
                {{ account.commission }} %
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Total Booking</th>
              <th class="fs-12">{{ bookings.length }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Account Added On</th>
              <th class="fs-12">
                {{ account.created | moment("dddd, MMMM Do YYYY") }}
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Actions</th>
              <th class="fs-12">
                <a @click="editAgent">Edit Agent</a>
                <a class="ml-5 red--text">Delete Agent</a>
              </th>
            </tr>
          </tbody>
        </v-simple-table>
        <h2 class="mt-10 primary--text mb-5">Login Accounts</h2>
        <v-layout row wrap class="grey darken-4 pa-3 mb-5">
          <v-flex xs12 class="pa-3"><p class="ma-0 pa-0 primary--text uppercase"><u>Add a New Account</u></p></v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Name</p>
            <v-text-field
              label="Full Name"
              single-line
              outlined
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Email</p>
            <v-text-field
              label="Email"
              single-line
              outlined
              v-model="email"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Phone</p>
            <v-text-field
              label="Phone"
              single-line
              outlined
              v-model="phone"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Password</p>
            <v-text-field
              label="Password"
              single-line
              outlined
              v-model="password"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <v-btn
              large
              block
              class="primary black--text"
              @click="createAccount"
              >Submit</v-btn
            >
          </v-flex>
          <v-flex xs12>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left uppercase primary--text">Name</th>
                    <th class="text-left uppercase primary--text">Phone</th>
                    <th class="text-left uppercase primary--text">Email</th>
                    <th class="text-left uppercase primary--text">Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in user_accounts" :key="item._id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.role }}</td>
                    <td>
                      <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="black--text"
                      light
                      block
                      v-bind="attrs"
                      v-on="on"
                    >
                      Manage<span><v-icon>mdi-menu-down</v-icon></span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        @click="openPasswordDialog(item._id)"
                        style="cursor: pointer"
                        >Set Password</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="deleteAccount(item._id)" style="cursor: pointer;">Delete Account</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md12 lg5 class="pl-2 pr-2"  v-if="account.payment_mode == 'Credit'">
        <v-simple-table>
          <tbody>
            <tr>
              <th class="fs-12 primary--text">Credit Limit</th>
              <th class="fs-12">{{ account.credit }} AED</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Payment Interval</th>
              <th class="fs-12">{{ account.payment_interval }} DAYS</th>
            </tr>
            
            <tr>
              <th class="fs-12 primary--text">Last Payment Amount</th>
              <th class="fs-12">
                <b>{{ account.lastPaymentAmount }} AED</b>
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Last Payment Date</th>
              <th class="fs-12">
                <b>{{
                  account.lastPaymentDate | moment("dddd, MMMM Do YYYY")
                }}</b>
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Payment Due</th>
              <th class="fs-12">
                <b>{{ amount_due }} AED</b>
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text">Payment Due On</th>
              <th class="fs-12">
                <b>{{
                  account.nextPaymentDate | moment("dddd, MMMM Do YYYY")
                }}</b>
              </th>
            </tr>
          </tbody>
        </v-simple-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          Set Agent Password
        </v-card-title>
        <v-card-text class="pt-5 pb-10">
          <v-text-field
            single-line
            outlined
            v-model="password"
            label="Password"
            class="mt-5"
          ></v-text-field>
          <v-text-field
            single-line
            outlined
            v-model="confirmPassword"
            label="Confirm Password"
            class="mt-5"
          ></v-text-field>
          <v-btn @click="setPassword" class="primary black--text mt-5" x-large
            >SET PASSWORD</v-btn
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          Password Changed
        </v-card-title>
        <v-card-text>
          <p class="mt-5">
            Please save or download the password for this account. You will not
            be able to view the view this password again.
          </p>
          <div
            class="pa-3"
            style="
              border: 1px solid #424242;
              border-radius: 5px;
              margin-top: 10px;
            "
          >
            <p class="accent--text">{{ password }}</p>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="copy"> Copy </v-btn>
          <v-btn color="primary" text @click="downloadCSV"> Download </v-btn>
          <v-btn color="primary" text @click="dialog2 = false"> CLOSE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { BASE_URL } from "@/config";
import Axios from "axios";
import EditAgent from "./EditAgent.vue";

export default {
  data() {
    return {
      account: [],
      dialog: false,
      dialog2: false,
      password: "",
      confirmPassword: "",
      bookings: [],
      amount_due: 0,
      user_accounts: [],
      account_id : ""
    };
  },
  mounted() {
    this.fetchAccount();
    this.fetchBookings();
    this.fetchUserAccounts();
  },
  methods: {
    async createAccount() {
      let url = BASE_URL + "/admin";
      let payload = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        role: "Agent",
        profileType: "agent",
        profile: this.$route.params.id,
        password: this.password,
      };
      let { data } = await Axios.post(url, payload);
    },
    openPasswordDialog(id){
      this.dialog = true
      this.account_id = id
    },
    downloadCSV() {
      const userData = [
        {
          name: this.account.name,
          email: this.account.email,
          password: this.password,
        },
      ];
      const csvContent = this.convertToCSV(userData);
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.account.name + ".csv";
      link.click();
    },
    convertToCSV(data) {
      const header = Object.keys(data[0]).join(",");
      const rows = data.map((obj) => Object.values(obj).join(",")).join("\n");
      return `${header}\n${rows}`;
    },
    async fetchAccount() {
      let url = BASE_URL + "/agent/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.account = data;
    },
    async fetchUserAccounts() {
      let url = BASE_URL + "/admin?agent_id=" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.user_accounts = data;
    },
    editAgent() {
      this.$router.push({
        name: "EditAgent",
        params: { id: this.$route.params.id },
      });
    },
    async deleteAccount(id){
      var context = this
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ba9d73',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let url = BASE_URL + "/admin/"+id
      let {data} = await Axios.delete(url)
      this.fetchUserAccounts()
         context.$swal.fire(
            'Deleted!',
            'Your item has been deleted.',
            'success'
          );
          // Add your deletion logic here
        } else if (result.dismiss === context.$swal.DismissReason.cancel) {
          context.$swal.fire(
            'Cancelled',
            'Your account is safe :)',
            'error'
          );
        }
      });
      // let url = BASE_URL + "/admin/delete/"+id
      // let {data} = await Axios.delete(url)
      // this.fetchUserAccounts()
    },
    async setPassword() {
      if (this.password == this.confirmPassword) {
        let url = BASE_URL + "/admin/password/" + this.account_id;
        let payload = {
          user: this.account_id,
          password: this.password,
        };
        let { data } = await Axios.patch(url, payload);
        this.dialog = false;
        this.dialog2 = true;
      } else {
      }
    },
    async fetchBookings() {
      var q_params = "?agent_id=" + this.$route.params.id;
      const headers = {
        Authorization:
          "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
      };
      let url = BASE_URL + "/booking/agent/list" + q_params;
      let { data } = await Axios.get(url, { headers });
      this.bookings = data;
      this.amount_due = Math.round(
        this.bookings.reduce((total, booking) => total + booking.amount, 0)
      );
    },
  },
};
</script>
  
    <style scoped>
.fs-12 {
  font-size: 12pt !important;
  font-weight: 400 !important;
}
</style>